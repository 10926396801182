import $ from 'jquery';
import prestashop from 'prestashop';

/**
 * Update address form on country change
 * Emit "addressFormUpdated" event
 *
 * @param selectors
 */
function handleAddressFormChange (selectors) {
  $('body').on('change', selectors.country + ', ' + selectors.type_address, () => {
    var requestData = {
      id_country: $(selectors.country + ':nth(0)').length ? $(selectors.country + ':nth(0)').val() : false,
      id_country_invoice: $(selectors.country + ':nth(1)').length ? $(selectors.country + ':nth(1)').val() : false,
      id_address: $(selectors.address + ' form').data('id-address'),
      type_address: $(selectors.address + ' form').find(selectors.type_address +':checked').val(),
    };

    var getFormViewUrl = $(selectors.address + ' form').data('refresh-url');
    var formFieldsSelector = selectors.address + ' input:not('+selectors.type_address+')';

    var invoiceAddress = $('.js-show-form-invoice:checked').length;

    $.post(getFormViewUrl, requestData).then((resp) => {
      var inputs = [];

      // Store fields values before updating form
      $(formFieldsSelector).each(function () {
        inputs[$(this).prop('name')] = $(this).val();
      });

      $(selectors.address).replaceWith(resp.address_form);

      if (invoiceAddress) {
        $('.form-invoice-only').addClass('is-active');
        $('.js-show-form-invoice').prop('checked', 1);
      }

      // Restore fields values
      $(formFieldsSelector).each(function () {
        $(this).val(inputs[$(this).prop('name')]);
      });

      prestashop.emit('updatedAddressForm', {target: $(selectors.address), resp: resp});
    }).fail((resp) => {
      prestashop.emit('handleError', {eventType: 'updateAddressForm', resp: resp});
    });
  });
}

$(document).ready(() => {
  handleAddressFormChange({
    'country': 'select.js-country',
    'type_address': 'input[name="type_address"]',
    'address': '.js-address-form',
  });
});